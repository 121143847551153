'use client';

import { useEffect } from 'react';

//todo: move to app/components/base
export default function BaseError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <h2>{error.message}</h2>
      <button onClick={() => reset()}> Try again</button>
    </div>
  );
}
